import React from 'react';
import {Dropdown, Form, Icon, Label, Message, Segment} from "semantic-ui-react";
import './App.css';
import examples from './examples.json'

function getit(premise: string, hypothesis: string) {
    const body = JSON.stringify({premise, hypothesis});
    return fetch("https://text-engine.lyre.ai/unstable/sent_mnli", {
        method: "POST",
        body,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
    })
}

const baselineConf = .5;


const dropdownOpts = examples.map((x, i) => ({key: i, text: x[0], value: i}));

class App extends React.Component {

    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            premise: "",
            hypothesis: "",
            loading: false,
            error: undefined,
            result: {
                data: []
            }
        };
    }

    render() {
        // @ts-ignore
        return (
            <Segment>
                <Segment>
                    <Label content={'Examples'} attached={'top left'}/>
                    <Dropdown placeholder='Click For Example Options' fluid search selection options={dropdownOpts}
                              onChange={(_, x) => {
                                  if (examples[(x as any)['value']] === undefined) {
                                      return;
                                  }
                                  this.setState({
                                      premise: examples[(x as any)['value']][2],
                                      hypothesis: examples[(x as any)['value']][1],
                                  })
                              }}/>
                </Segment>
                <Form>
                    <Segment>
                        <Label attached={'top left'} content={"Text"}/>
                        <Form.TextArea value={(this.state as any).premise} onChange={(_, {value}) => {
                            this.setState({premise: value});
                        }} rows={20}/>
                    </Segment>
                    <Segment>
                        <Label attached={'top left'} content={"Hypothesis"}/>
                        <Form.Input fluid value={(this.state as any).hypothesis} onChange={(_, {value}) => {
                            this.setState({hypothesis: value});
                        }}/>
                    </Segment>
                    <Form.Button loading={(this.state as any).loading}
                            onClick={async () => {
                                if ((this.state as any).premise.length * (this.state as any).hypothesis.length === 0) {
                                    return;
                                }
                                if ((this.state as any).loading) {
                                    return;
                                }
                                this.setState({loading: true});
                                try {
                                    // @ts-ignore
                                    let hyp = this.state.hypothesis;
                                    if (!hyp.endsWith(".")) {
                                        hyp += "."
                                    }
                                    // @ts-ignore
                                    const x = await getit(this.state.premise, hyp).then(response => response.json());
                                    this.setState({result: x});
                                } catch (e) {
                                    console.error("failed to fetch", e);
                                    this.setState({error: "An unexpected error has occurred."})
                                } finally {
                                    this.setState({loading: false});
                                }
                            }}
                            content={'Submit'}/>
                </Form>
                {(() => {
                    // @ts-ignore
                    if (this.state.error) {
                        // @ts-ignore
                        return <Message negative content={this.state.error}/>
                    }
                    return [];
                })()}
                <Segment disabled={(this.state as any).result.data.length === 0}>
                    <Segment padded loading={(this.state as any).loading}>
                        {(() => {
                            if ((this.state as any).result.data.length === 0) {
                                return [];
                            }
                            const yes = (this.state as any).result.data
                                .map((x: { entail: number; }) => x.entail)
                                .reduce((a: number, b: number) => a > b ? a : b);
                            const no = (this.state as any).result.data
                                .map((x: { contradict: number; }) => x.contradict)
                                .reduce((a: number, b: number) => a > b ? a : b);
                            const result = yes > baselineConf && yes > no ? "Yes" : no > baselineConf && no > yes ? "No" : "Inconclusive";
                            if (result === "Yes") {
                                return <p><Icon size={'huge'} color={'green'} name={"check square"}/> The hypothesis
                                    appears
                                    to be correct</p>
                            }
                            if (result === "No") {
                                return <p><Icon size={'huge'} color={'red'} name={"times rectangle"}/>The hypothesis
                                    appears
                                    to be incorrect</p>
                            }
                            return (
                                <p>The text does not conclusively support or contradict the hypothesis</p>
                            );
                        })()}
                    </Segment>
                    <Segment>
                        {
                            (this.state as any).result.data.map((x: any) => {
                                let color = undefined;
                                if (x.entail > baselineConf) {
                                    color = "olive";
                                }
                                if (x.contradict > baselineConf) {
                                    color = "orange";
                                }

                                // @ts-ignore
                                return <Label content={x.sentence} color={color}/>
                            })
                        }
                    </Segment>
                    <Segment>
                        {
                            (this.state as any).result.data.map((x: any) => <Segment>
                                sentence: <Label content={x.sentence}
                                                 color={x.entail > baselineConf ? 'olive' : x.contradict > baselineConf ? 'orange' : undefined}/>
                                <br/>
                                "YES" confidence: {x.entail}
                                <br/>
                                "NO" confidence: {x.contradict}
                                <br/>
                                "UNRELATED" confidence: {x.neutral}
                            </Segment>)
                        }
                    </Segment>
                </Segment>
            </Segment>
        );
    }
}

export default App;
